<template>
  <div class="f-input f-input-file">
    <slot name="label" :label="label ?? ''">
      <div class="f-input__label" v-text="label" />
    </slot>

    <q-file v-bind="qInputProps" v-model="modelValue">
      <template v-for="(_, slotName) in getSlots($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps || {}" />
      </template>
    </q-file>
  </div>
</template>

<script setup lang="ts">
import type { QFileProps, QFileSlots } from 'quasar';
import { computed } from 'vue';

import type { FInputFileProps } from '@/types/formInput';

const props = withDefaults(defineProps<FInputFileProps>(), {
  accept: [
    'application/msword',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.rar',
    'application/zip',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/tif',
    'image/tiff',
  ].join(','),
});

const modelValue = defineModel<QFileProps['modelValue']>('modelValue');

const qInputProps = computed(() => {
  const p = { ...props };

  delete p.label;

  return p;
});

// hacks needed to preserve slot name and prop types.
const getSlots = (slots: unknown) => slots as QFileSlots;
</script>
